<template>
    <div class="layout-device">
        <Spin fix v-if="deviceInfoLoading"></Spin>
        <div class="device-menu-edit">
            <div class="menu-top">
                <div class="menu-tab">
                    <Breadcrumb>
                        <BreadcrumbItem to="/device/list">设备知识库</BreadcrumbItem>
                        <BreadcrumbItem>设备详情</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div class="menu-info" :class="structuralId === ''?'menu-info-active':''" @click="onDevice">
                    <span class="info-image" :style="'background-image:url('+deviceInfo.equipmentPictures+')'"></span>
                    <div class="info-name-box"><p class="info-name">{{ deviceInfo.equipmentName }}</p></div>
                </div>
                <div class="menu-list-top">
                    <h1 class="title">部件列表（{{ deviceInfo.partsSize }}）</h1>
                </div>
            </div>
            <div class="menu-list">
                <div class="list-item">
                    <div class="item" :key="'item'+key" v-for="(item,key) in deviceStructuralArray">
                        <div class="item-thead" :class="item.id === structuralId?'item-active':''">
                            <a href="javascript:" class="href">
                                <Icon v-if="item.childNode.length !== 0" :type="item.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon"  @click="item.visible = !item.visible"/>
                                <p :style="item.childNode.length === 0?'margin-left:25px':''" @click="onSelectPart(item)">{{ item.partsName }}</p>
                            </a>
                        </div>
                        <div class="item-body" v-if="item.visible">
                            <div class="item-child" :key="'child2'+key" v-for="(children,key) in item.childNode">
                                <div class="child-thead" :class="children.id === structuralId?'children-active':''" :style="children.childNode.length === 0?'padding-left:20px':''">
                                    <a href="javascript:" class="href">
                                        <Icon v-if="children.childNode.length !== 0"  @click="children.visible = !children.visible" :type="children.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon" />
                                        <p :style="children.childNode.length === 0?'margin-left:20px':''" @click="onSelectPart(children)">{{ children.partsName }}</p>
                                    </a>
                                </div>
                                <div class="child-body" v-if="children.visible">
                                    <ul>
                                        <li :key="'child3'+key" v-for="(child,key) in children.childNode" :class="child.id === structuralId?'child-active':''" >
                                            <a href="javascript:" class="href" @click="onSelectPart(child)">
                                                <Icon type="ios-copy-outline" class="icon"/>
                                                {{ child.partsName }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-device-edit" :class="deviceTabsNumber === 4?'device-bg':''">
            <div class="layout-device-tab">
                <div class="tab-li">
                    <div class="tabs-tab" :key="key" v-for="(tabs,key) in deviceTabs" :class="deviceTabsNumber === tabs.label?'active':''">
                        <a href="javascript:" @click="onTabsSwitch(tabs.label)">
                            <p v-if="tabs.label === 6">{{ tabs.value }}</p>
                            <p v-if="tabs.label === 7">{{ tabs.value }}</p>
                            <p v-if="tabs.label === 9">{{ tabs.value }}</p>
                            <p v-if="tabs.label === 1">{{ `${tabs.value}  （${structuralId===''? deviceInfo.instructionUseFileSize : structuralInfo.instructionUseFileSize}）`}}</p>
                            <p v-if="tabs.label === 2">{{ `${tabs.value}  （${structuralId===''? deviceInfo.mechanicalAtlasFileSize : structuralInfo.mechanicalAtlasFileSize}）`}}</p>
                            <p v-if="tabs.label === 3">{{ `${tabs.value}  （${structuralId===''? deviceInfo.electricalAtlasFileSize : structuralInfo.electricalAtlasFileSize}）`}}</p>
                            <p v-if="tabs.label === 8">{{ tabs.value }}</p>
                            <p v-if="tabs.label === 5">{{ `${tabs.value}  （${structuralId===''? deviceInfo.faultAnalysisSize : structuralInfo.faultAnalysisSize}）`}}</p>
                            <p v-if="tabs.label === 0">{{ `${tabs.value}  （${structuralId===''? deviceInfo.otherUseFileSize : structuralInfo.otherUseFileSize}）`}}</p>
                        </a>
                    </div>
                </div>
                <div class="layout-device-content">
                    <div class="layout-device-info-li" v-if="deviceTabsNumber === 6 && structuralId === ''">
                        <div class="box">
                            <ul>
                                <li class="item">
                                    <span class="label required">目录：</span>
                                    <Cascader style="width: 300px" :disabled="true"  :data="classifyMenuArray" v-model="newlyParams.deviceType"></Cascader>
                                </li>
                                <li class="item">
                                    <span class="label required">设备名称：</span>
                                    <Input v-model="newlyParams.deviceName" :disabled="true" maxlength="25" show-word-limit placeholder="请输入"  style="width: 300px" />
                                </li>
                                <li class="item">
                                    <span class="label required">设备型号：</span>
                                    <Select v-model="newlyParams.deviceModel" clearable :disabled="true" style="width:300px">
                                        <Option v-for="(deviceModel,key) in deviceModelArray" :value="deviceModel.id" :key="key">{{ deviceModel.name }}</Option>
                                    </Select>
                                </li>
                                <li class="item">
                                    <span class="label required">生产厂家：</span>
                                    <Select v-model="newlyParams.manufacturer" :disabled="true" clearable style="width:300px">
                                        <Option v-for="(manufacturer,key) in manufacturerArray" :value="manufacturer.id" :key="key">{{ manufacturer.name }}</Option>
                                    </Select>
                                </li>
                                <li class="item">
                                    <span class="label">设备图片：</span>
                                    <div class="device-upload">
                                        <div class="file-image">
                                            <img v-if="imageSrc !== ''" :src="imageSrc" class="image"/>
                                            <div class="image-error" v-if="imageSrc === ''">
                                                <div class="box">
                                                    <span class="table-image"><Icon type="ios-images" size="30"/><font >未添加图片</font></span>
                                                </div>
                                            </div>
                                            <div class="handle" v-if="imageSrc !== ''" >
                                                <div class="box">
                                                    <a href="javascript:" @click="onImageAmplify(deviceInfoObj.name,deviceInfoObj.equipmentPictures,deviceInfoObj.equipmentPicturesUrl)"><Icon custom="look-big" :size="25"/></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-box"  v-if="deviceTabsNumber === 6 && structuralId !== ''">
                        <div class="tab-part-content">
                            <!--s: 右侧-->
                            <div class="part-right">
                                <div v-if="JSON.stringify(deviceSelectPart) === '{}' || deviceSelectPart.fileUrl === ''" class="part-right-no-image"></div>
                                <div v-if="JSON.stringify(deviceSelectPart) !== '{}' && deviceSelectPart.fileUrl !== ''" class="part-right-content">
                                    <span v-if="onFileFormatCheck(deviceSelectPart.fileUrl)" :style="'background-image:url('+deviceSelectPart.file+')'" class="image"></span>
                                    <video v-if="!onFileFormatCheck(deviceSelectPart.fileUrl) && deviceSelectPart.file" :src="deviceSelectPart.file" width="100%" height="100%" autoplay controls class="file-video"></video>
                                </div>
                                <div class="part-top-bar" v-if="JSON.stringify(deviceSelectPart) !== '{}'">
                                    <div class="bar-body">
                                        <Poptip placement="top" theme="light">
                                            <a href="javascript:" class="info"></a>
                                            <div slot="content"  class="bar-content">
                                                <span class="title"><font></font> 部件型号：</span>
                                                <div class="text">{{ deviceSelectPart.partsModel === ''?'未添加部件型号':deviceSelectPart.partsModel }}</div>
                                                <span class="title"><font></font> 部件说明：</span>
                                                <div class="text">{{ deviceSelectPart.partsBlurb === ''?'未添加简介':deviceSelectPart.partsBlurb }}</div>
                                            </div>
                                        </Poptip>
                                        <a href="javascript:" class="look" @click="onImageAmplify(deviceSelectPart.partsName,deviceSelectPart.file,deviceSelectPart.fileUrl)"></a>
                                    </div>
                                </div>
                            </div>
                            <!--e: 右侧-->
                        </div>
                    </div>
                    <div class="tab-box" v-if="deviceTabsNumber === 7">
                        <div class="device-info-con">
                            <div class="device-info-content">
                                <div class="content">{{ deviceInfoObj.introContent.length === 0?'暂无简介':deviceInfoObj.introContent }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-box" v-if="deviceTabsNumber === 9">
                        <div class="device-info-parameter" :style="'height:'+parameterHeight+'px'">
                            <div class="parameter-list">
                              <div class="box" :key="key" v-for="(deviceInfo,key) in deviceInfoArray">
                                <div class="list-title">
                                  <div class="list-name">
                                    <Icon type="ios-square" size="18"/>
                                    <p class="name">{{ deviceInfo.titleName }}</p>
                                  </div>
                                </div>
                                <div class="list-table">
                                  <Table border :columns="parameterColumns" :data="deviceInfo.paramDetail">
                                    <template slot-scope="{ row }" slot="名称">
                                      <div class="list-input">
                                        {{ row.paramName }}
                                      </div>
                                    </template>
                                    <template slot-scope="{ row }" slot="参数">
                                      <div class="list-input">
                                        {{ row.paramValue }}
                                      </div>
                                    </template>
                                  </Table>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-box" v-if="deviceTabsNumber === 8">
                        <div class="device-info-principle" v-if="deviceInfoObj.detailDTOList && deviceInfoObj.detailDTOList.length!==0 || deviceInfoObj.principleContent!==''">
                            <div class="principle-video">
                                <video v-if="principleObj.fileFormat === 'video'" :src="principleObj.file" width="100%" height="100%" controls class="file-video"></video>
                                <div class="image-big" v-if="principleObj.fileFormat === 'image'" :style="'background-image:url('+principleObj.file+')'"></div>
                            </div>
                            <div class="principle-text">
                                <div class="title-tab">
                                    <ul>
                                        <li :class="principleTab === 1?'active':''" @click=" principleTab = 1" v-if="deviceInfoObj.detailDTOList && deviceInfoObj.detailDTOList.length!==0"><a href="javascript:">资料列表（{{ deviceInfoObj.detailDTOList.length }}）</a></li>
                                        <li :class="principleTab === 2?'active':''" @click=" principleTab = 2" v-if="deviceInfoObj.principleContent !== ''"><a href="javascript:">原理简介</a></li>
                                    </ul>
                                </div>
                                <div class="text-box" v-if="principleTab === 1">
                                    <ul>
                                        <li :class="principleObj.id === list.id?'active':''" @click="onSelectPrinciple(list)" :key="key" v-for="(list,key) in deviceInfoObj.detailDTOList">
                                            <div class="box">
                                                <div class="video" v-if="list.fileFormat === 'video'">
                                                    <video :src="list.file" width="100%" height="100%" controls class="file-video"></video>
                                                </div>
                                                <div class="image" v-if="list.fileFormat === 'image'">
                                                    <span :style="'background-image:url('+list.file+')'"></span>
                                                </div>
                                            </div>
                                            <div class="name">{{ list.title }}</div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="text" v-if="principleTab === 2">{{ deviceInfoObj.principleContent }}</div>
                            </div>
                        </div>
                        <div class="device-info-principle-no-data" v-if="deviceInfoObj.detailDTOList && deviceInfoObj.detailDTOList.length===0 && deviceInfoObj.principleContent===''">暂无内容</div>
                    </div>
                    <div class="tab-box"  v-if="deviceTabsNumber === 5">
                        <div class="table">
                            <Table ref="selection" :loading="deviceInfoLoading" stripe="stripe" :height="faultTableHeight" :columns="faultDataColumns" :data="deviceInfoArray">
                                <template slot-scope="{ index }" slot="序号">
                                    {{ index+1 }}
                                </template>
                                <template slot-scope="{ row }" slot="故障代码">
                                    {{ row.code || '/' }}
                                </template>
                            </Table>
                        </div>
                    </div>
                    <div class="tab-box"  v-if="deviceTabsNumber === 1">
                        <div class="table">
                            <Table ref="selection" :loading="deviceInfoLoading" stripe="stripe" :height="faultTableHeight" :columns="deviceDataColumns" :data="deviceInfoArray">
                                <template slot-scope="{ index }" slot="序号">
                                    {{ index+1 }}
                                </template>
                                <template slot-scope="{ row }" slot="文件名称">
                                    <a href="javascript:" class="target" @click="onImageAmplify(row.fileName,row.file,row.fileUrl)">{{ row.fileName }}</a>
                                </template>
                                <template slot-scope="{ row }" slot="文件大小">
                                    {{ renderSize(row.fileSize) }}
                                </template>
                                <template slot-scope="{ row }" slot="操作">
                                    <a href="javascript:" @click="onImageAmplify(row.fileName,row.file,row.fileUrl)">查看</a>
                                    <a href="javascript:" @click="onDownLoad(row.file,row.fileName)">下载</a>
                                </template>
                            </Table>
                        </div>
                    </div>
                    <div class="tab-box"  v-if="deviceTabsNumber === 2">
                        <div class="table">
                            <Table ref="selection" :loading="deviceInfoLoading" stripe="stripe" :height="faultTableHeight" :columns="deviceDataColumns" :data="deviceInfoArray">
                                <template slot-scope="{ index }" slot="序号">
                                    {{ index+1 }}
                                </template>
                                <template slot-scope="{ row,index }" slot="文件名称">
                                    <a href="javascript:" class="target" @click="onImageSwitch(row,deviceInfoArray,index)">{{ row.fileName }}</a>
                                </template>
                                <template slot-scope="{ row }" slot="文件大小">
                                    {{ renderSize(row.fileSize) }}
                                </template>
                                <template slot-scope="{ row,index }" slot="操作">
                                    <a href="javascript:" @click="onImageSwitch(row,deviceInfoArray,index)">查看</a>
                                    <a href="javascript:" @click="onDownLoad(row.file,row.fileName)">下载</a>
                                </template>
                            </Table>
                        </div>
                    </div>
                    <div class="tab-box"  v-if="deviceTabsNumber === 3">
                          <div class="table text-btn">
                              <Table ref="selection" stripe="stripe" :loading="deviceInfoLoading" :height="faultTableHeight" :columns="deviceDataColumns" :data="deviceInfoArray">
                                  <template slot-scope="{ index }" slot="序号">
                                      {{ index+1 }}
                                  </template>
                                  <template slot-scope="{ row }" slot="文件名称">
                                      <a href="javascript:" class="blue" v-if="!row.visible" @click="onElectricalEdit(row.id)">{{ row.fileName }}</a>
                                  </template>
                                  <template slot-scope="{ row }" slot="文件大小">
                                      {{ renderSize(row.fileSize) }}
                                  </template>
                                  <template slot-scope="{ row }" slot="操作">
                                      <a href="javascript:" class="blue" @click="onDownLoadImage(row)">下载</a>
                                  </template>
                            </Table>
                        </div>
    <!--                    <div class="card-list" v-if="deviceInfoArray && deviceInfoArray.length!==0" :class="menuVisible ? 'layout-visible': 'card-body-list'">-->
    <!--                        <ul>-->
    <!--                            <li class="card-list-li" :key="key" v-for="(deviceInfo,key) in deviceInfoArray" @click="onElectricalEdit(deviceInfo.id)">-->
    <!--                                <div class="process-card">-->
    <!--                                    <span class="card-image"><img :src="deviceInfo.file"/></span>-->
    <!--                                    <div class="card-content">-->
    <!--                                        <span class="card-li">上传时间：{{ deviceInfo.uploadTime }}</span>-->
    <!--                                        <span class="card-li">修改时间：{{ deviceInfo.updateTime }}</span>-->
    <!--                                        <span class="card-li">文件大小：{{ renderSize(deviceInfo.fileSize) }}</span>-->
    <!--                                    </div>-->
    <!--                                    <div class="edit" @click.stop>-->
    <!--                                        <Dropdown placement="bottom-end">-->
    <!--                                            <a href="javascript:void(0)" class="edit-size">-->
    <!--                                                <Icon type="ios-more"></Icon>-->
    <!--                                            </a>-->
    <!--                                            <DropdownMenu slot="list">-->
    <!--                                                <DropdownItem @click.native="onDownLoadImage(deviceInfo)">下载</DropdownItem>-->
    <!--                                                <DropdownItem @click.native="onElectricalEdit(deviceInfo.id)">查看</DropdownItem>-->
    <!--                                            </DropdownMenu>-->
    <!--                                        </Dropdown>-->
    <!--                                    </div>-->
    <!--                                    <div class="badge" :class="deviceInfo.status === 0?'red':'green'">{{ deviceInfo.status === 0?'未发布':'已发布' }}</div>-->
    <!--                                </div>-->
    <!--                                <div class="card-name-box">-->
    <!--                                    <p class="name" v-if="!deviceInfo.nameStatus">{{ deviceInfo.fileName }}</p>-->
    <!--                                    <Input ref="electricalName" v-model="deviceInfo.fileName" maxlength="20" v-if="deviceInfo.nameStatus" @on-blur="onUpdateName(deviceInfo.id,key)" @on-enter="$event.target.blur()" placeholder="请输入名称" class="name" />-->
    <!--                                </div>-->
    <!--                            </li>-->
    <!--                        </ul>-->
    <!--                        <Spin fix v-if="deviceInfoLoading"></Spin>-->
    <!--                    </div>-->
    <!--                    <div class="card-no-number" :style="'height:'+faultTableHeight+'px'" v-if="deviceInfoArray && deviceInfoArray.length===0">暂无数据</div>-->
                    </div>
                    <div class="tab-box" v-if="deviceTabsNumber === 0">
                        <div class="table">
                            <Table ref="selection" :loading="deviceInfoLoading" stripe="stripe" :height="faultTableHeight" :columns="deviceDataColumns" :data="deviceInfoArray">
                                <template slot-scope="{ index }" slot="序号">
                                    {{ index+1 }}
                                </template>
                                <template slot-scope="{ row }" slot="文件名称">
                                    <a href="javascript:" class="target" @click="onImageAmplify(row.fileName,row.file,row.fileUrl)">{{ row.fileName }}</a>
                                </template>
                                <template slot-scope="{ row }" slot="文件大小">
                                    {{ renderSize(row.fileSize) }}
                                </template>
                                <template slot-scope="{ row }" slot="操作">
                                    <a href="javascript:" @click="onImageAmplify(row.fileName,row.file,row.fileUrl)">查看</a>
                                    <a href="javascript:" @click="onDownLoad(row.file,row.fileName)">下载</a>
                                </template>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <!--s: Modal 查看可能原因-->
            <Modal
                v-model="reasonLookVisible"
                title="解决方案"
                width="600"
                footer-hide>
                <div class="device-modal">
                    <span class="label">可能原因：</span>
                    <div class="text-label">{{ reasonArray.reason }}</div>
                </div>
                <div class="device-modal">
                    <span class="label">处理方法：</span>
                    <ul class="modal-ul">
                        <li :key="key" v-for="(reason,key) in reasonArray.solutionList">
                            <div class="image-error" v-if="reason.picUrl === ''" >
                                <div class="box">
                                    <span class="table-image"><Icon type="ios-images" size="20"/><font >未添加图片</font></span>
                                </div>
                            </div>
                            <div class="image" v-if="reason.picUrl !== ''">
                                <div class="upload-image-box">
                                    <span :style="'background-image:url('+reason.picUrl+')'" class="image"></span>
                                    <div class="handle">
                                        <div class="box">
                                            <a href="javascript:" @click="onImageAmplify(reasonArray.reason,reason.picUrl,reason.solutionResourcesFileUrl)"><Icon custom="look-big" :size="25"/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-info">{{ reason.solution }}</div>
                        </li>
                    </ul>
                </div>
            </Modal>
            <!--e: Modal 查看可能原因-->

            <!--s: Modal 查看大图-->
            <ViewImage v-model="viewImageVisible" :imageName="imageName" :imageUrl="imageSrc" />
            <!--e: Modal 查看大图-->
            <!--s: Modal 查看大图图册-->
            <div class="device-modal-list-box" v-if="imageBigLookVisible">
                <div class="modal-title"><span>{{ imageObj.solution }}</span></div>
                <a class="modal-clear" @click="imageBigLookVisible = false;imageRatio = 100;imageBigNumber = 0"><Icon type="md-add" size="30" /></a>
                <div class="modal-image-box" @mousewheel="onMousewheel">
                    <a href="javascript:" class="carousel-btn carousel-l" @click="onImageBigLeft"><Icon type="ios-arrow-back" size="30"/></a>
                    <a href="javascript:" class="carousel-btn carousel-r" @click="onImageBigRight"><Icon type="ios-arrow-forward" size="30"/></a>
                    <div class="image-box-mouse" @mousedown="onMouseDown"
                         @mouseup="onMouseUp"
                         @mouseout="onMouseUp"
                         @mousemove="onMouseMove"
                         :style="'width:'+(imageWidth*(imageRatio/100))+'px;height:'+(imageHeight*(imageRatio/100))+'px;left:'+imageLeft+'px;top:'+imageTop+'px'">
                        <img :src="imageObj.picUrl"/>
                    </div>
                </div>
                <div class="modal-control-box">
                    <Tooltip content="放大" placement="top"><a href="javascript:" @click="onImageEnlarge"><i class="enlarge"></i></a></Tooltip>
                    <Tooltip content="缩小" placement="top"><a href="javascript:" @click="onImageNarrow"><i class="narrow"></i><span class="b-before"></span></a></Tooltip>
                    <Tooltip content="图片还原" placement="top"><a href="javascript:" @click="onReduction">{{ imageRatio }}%<span class="b-before"></span></a></Tooltip>
                </div>
                <div class="modal-list">
                    <div class="list-btn"><a href="javascript:" v-if="imageBigNumber!==0" @click="onImageLeft"><Icon type="ios-arrow-back" size="30"/></a></div>
                    <div class="list-box" id="carouselId">
                        <div class="carousel" :style="'transform: translate3d(-'+imageBigNumber*106+'px, 0px, 0px); width:'+106*imageBigArray.length+'px;transition: transform 500ms ease 0s;'">
                            <a href="javascript:" :key="key" @click="onImageSwitch(image,imageBigArray,key)" :class="imageBigActive === key?'active':''" v-for="(image,key) in imageBigArray">
                                <span class="table-image" :style="'background-image:url('+image.file+')'"></span>
                            </a>
                        </div>
                    </div>
                    <div class="list-btn"><a href="javascript:" v-if="imageBigNumber*106<imageBigArray.length*106-offsetWidth" @click="onImageRight"><Icon type="ios-arrow-forward" size="30"/></a></div>
                </div>
            </div>
            <!--e: Modal 查看大图可能原因-->
        </div>
    </div>
</template>
<script>
import DeviceInfo from './info'
export default DeviceInfo
</script>
<style lang="less">
@import "info";
</style>
